import { Box, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PageWrapper = styled(Box)(({ theme }) => ({
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  padding: theme.spacing(0, 2, 5, 2),
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 5, 5, 5),
  },
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // paddingBottom: theme.spacing(9),
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  ...theme.typography.Body_semibold_20,
  textAlign: "center",
  paddingBottom: theme.spacing(2.8),

  [theme.breakpoints.up("lg")]: {
    ...theme.typography.Body_semibold_32,
  },
}));

export const PageSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_14,
  textAlign: "center",
  color: theme.palette.grey.darker,
}));

export const InfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(3.5),
  marginBottom: theme.spacing(8),
}));

export const CommonWrapperExternal = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  maxWidth: '325px',
  margin: 'auto',
  border: '1px solid #dedede',
  borderRadius: '16px',
  padding: '10px',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '480px',
  },
  //   alignItems: "start ",
  //   justifyContent: "flex-start ",
}));
export const CommonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  //   alignItems: "start ",
  //   justifyContent: "flex-start ",
}));

export const ReadPermissionTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2.8),
  ...theme.typography.Body_medium_16,
  [theme.breakpoints.up("lg")]: {
    ...theme.typography.Body_medium_20,
  },
}));

export const LabelDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_14,
  [theme.breakpoints.up("lg")]: {
    ...theme.typography.Body_light_16,
  },
}));

export const OtherText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("lg")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const PermissionCardMainText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_14,
  color: theme.palette.grey.darker,
}));

export const PermissionCardSubText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_16,
  color: theme.palette.orange.main,
  [theme.breakpoints.up("lg")]: {
    ...theme.typography.Body_medium_20,
  },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  margin: theme.spacing(7, 0),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    width: "auto",
  },
}));

export const ProductCategoryInfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  marginTop: theme.spacing(5),
}));
export const DirectDebitWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
}));

export const ProductCategoryInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  marginLeft: theme.spacing(1.5),
  gap: theme.spacing(1.25),
}));

//
export const InfoTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_24,
}));

export const CostForService = styled(Box)(({ theme }) => ({
  backgroundColor: "#002638",
  border: "2px solid #002E45",
  borderRadius: "12px",
  padding: theme.spacing(1.5, 2),
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  minWidth: "100%",

  [theme.breakpoints.up("lg")]: {
    minWidth: "200px",
  },
}));


// ksdf
export const ExternalInfoTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_16,
  color: theme.palette.common.black,
  display: "inline-block",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_light_14,
  },
}));

export const ExternalMarkTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_16,
  color: theme.palette.orange.main,
  display: "inline-block",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_medium_14,
  },
}));


export const DefaultButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  border: "1px solid #dedede",
  cursor: "pointer",
  display: "inline-block",
  padding: "10px 20px",
  marginLeft: "10px",
  marginRight: "10px"
}));


export const ExternalAllowButton = styled(Button)(({ theme }) => ({
  background: theme.palette.orange.main,
  color: theme.palette.common.white,
  border: "1px solid #dedede",
  cursor: "pointer",
  display: "inline-block",
  padding: "10px 20px",
  marginLeft: "10px",
  marginRight: "10px"
}));