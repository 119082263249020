import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MainCard from "../../components/common/MainCard";

export const PageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 5, 2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 10, 5, 10),
  },
}));

export const MainCardStyled = styled(MainCard)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.primary.color1,
  },
}));

export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: "auto",
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    maxHeight: "380px",
  },
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  // border: "none",
  backgroundColor: theme.palette.primary.light,
  borderBottom: "3px solid transparent",
  color: theme.palette.grey.light,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const StyledTableCellData = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  ...theme.typography.Body_light_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_light_16,
  },
}));

export const StyledTableCellDataItem = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const ExpandListBtn = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: "12px",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "space-between",
  maxWidth: "160px",
  cursor: "pointer",
  color: theme.palette.common.white,
  textDecoration: "none",

  ":hover": {
    backgroundColor: theme.palette.primary.lighter,
  },

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const MobileBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: "12px",
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  textAlign: "center",
  display: "block",

  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));
