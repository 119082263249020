import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Stack,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { format } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import GetAxiosInstance from '../../components/common/api';
import { getUserData } from '../../components/common/common';
import Loader from '../../components/common/Loader';
import { CustomPaper, StyledDivider2 } from '../../components/common/style';
import StyledCircularProgress from '../../components/common/StyledCircularProgress';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import { ML_SELECTION_AVAILABLE } from '../../consts/app.const';
import banksList from '../../util/bankList';
import {
  CircularProgressWrapper,
  OverviewWrapper,
  PageBoxWrapper,
  PageHead,
  RiskCardInfoWrapper,
  TableCellDataAmount,
  TableCellDataItemName,
  TableCellHead,
  TableWrapper,
  TopCard,
  TopCardInfoBox,
  TopCardListItem,
  TopCardListWrapper,
} from './DashboardUIStyle';
import MonthlyIncome from './Tabs/MonthlyIncome';
import MonthlyExpenses from './Tabs/MonthlyExpenses';
import MonthlyAffordability from './Tabs/MonthlyAffordability';

const MuiCircularProgressStyle = styled(CircularProgress)(({ theme }) => ({
  '.MuiCircularProgress-svg': {
    height: '70px',
    width: '70px',
    transform: 'rotate(270deg)',
  },
}));

function CircularProgressWithLabel2({ value, ...others }) {
  return (
    <Box
      sx={{
        position: 'relative',
        '.MuiCircularProgress-root': {
          display: 'contents',
        },
      }}
    >
      <MuiCircularProgressStyle
        variant="determinate"
        value={value}
        {...others}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="Body_semibold_20" component="div" color="">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel2.propTypes = {
  value: PropTypes.number,
};

const monthRange = [
  {
    title: '1 Month',
    value: 1,
  },
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];

const typeOptions = [
  {
    title: 'Total',
    value: 'tran_amount_total_for_period',
  },
  {
    title: 'Monthly Average',
    value: 'tran_amount_monthly_average',
  },
];
const mlOptions = [
  {
    title: 'Text Based',
    value: false,
  },
  {
    title: 'Chat GPT',
    value: true,
  },
];

function createData(Item, Amount) {
  return { Item, Amount };
}

const Overview = () => {
  const theme = useTheme();
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [filletElement, setFilletElement] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [openIncome, setOpenIncome] = useState(false);
  const [loader, setLoader] = useState(false);
  const [totalIncome, setTotalIncome] = useState([]);
  const [totalExpense, setTotalExpense] = useState([]);
  const [totalAffordability, setTotalAffordability] = useState([]);
  const [totalInFlow, setTotalInFlow] = useState([]);
  const [totalOutFlow, setTotalOutFlow] = useState([]);
  const [riskIndicators, setRiskIndicators] = useState([]);
  const [selectAccount, setSelectAccount] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedML, setSelectedML] = useState(false);
  const [selectMonth, setSelectMonth] = useState();
  const [uniqueKey, setUniqueKey] = useState();
  const [selectedType, setSelectedType] = useState(
    'tran_amount_total_for_period'
  );
  const [bankList, setBankList] = useState([]);
  const [selectedBankLogo, setSelectedBankLogo] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [accountsNumberList, setAccountsNumberList] = useState();
  const [openExpenses, setOpenExpenses] = useState(false);
  const [consent, setConsent] = useState();
  const [grantInflowTotal, setGrantInflowTotal] = useState();
  const [grantOutFlowTotal, setGrantOutFlowTotal] = useState();
  const [dateToValueData, setDateToValueData] = useState('');
  const [monthlyLiquidity, setMonthlyLiquidity] = useState([]);
  const [liquidity_ResidualIncome, setLiquidity_ResidualIncome] = useState(0);
  const [liquidity_Predicted_balance, setLiquidity_Predicted_balance] =
    useState(0);
  const [selectedConsent, setSelectedConsent] = useState({});
  const [openAffordability, setOpenAffordability] = useState(false);
  const [switchState, setSwitchState] = useState(true); // Add state for switch
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpenIncomeDialog = () => {
    setOpenIncome(true);
  };
  const handleCloseIncomeDialog = () => {
    setOpenIncome(false);
  };
  const handleClickOpenExpensesDialog = () => {
    setOpenExpenses(true);
  };
  const handleCloseExpensesDialog = () => {
    setOpenExpenses(false);
  };
  const handleClickOpenAffordabilityDialog = () => {
    setOpenAffordability(true);
  };
  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };

  useEffect(() => {
    const fetchBankList = async () => {
      setBankList(
        await banksList({
          'journey-type': 'Dashboard',
        })
      );
    };
    fetchBankList();
  }, []);

  useEffect(() => {
    const fetchConsentData = async () => {
      if (bankList.length) {
        let hasAccessStatement = true;
        setLoader(true);
        let userData = getUserData();

        await GetAxiosInstance(
          `orchestrator/get-consent`,
          'get',
          {},
          { 'journey-type': 'Dashboard' }
        ).then((res) => {
          if (res.data?.codes === 200) {
            setUniqueKey(res.data?.data?.referralCode?.code);
            if (res.data?.data.consents.length) {
              let accountNumberList = [];
              setConsent(res.data?.data?.consents);
              res?.data?.data?.consents.map((v) => {
                if (
                  v.consent.data.products.filter(
                    (item) =>
                      item.code === 'affordability_basic' ||
                      item.code === 'affordability_full'
                  ).length
                )
                  if (v.accountNumber && v.dashboardAvailable) {
                    accountNumberList.push({
                      title: `XXXXXX${v.accountNumber.slice(-4)}`,
                      value: v.accountNumber,
                      icon: bankList.find((i) => i.bankName === v.bankName)
                        ?.icon,
                      bankName: bankList.find((i) => i.bankName === v.bankName)
                        ?.bankName,
                    });
                  }
              });
              if (!accountNumberList.length) {
                navigate('/statement');
                hasAccessStatement = false;
              }
              accountNumberList.push({
                title: 'CONSOLIDATED',
                value: 'all',
                icon: '',
                bankName: '',
              });
              setAccountsNumberList(accountNumberList);
            }
          } else {
            setLoader(false);
            toast.error(
              res?.response?.data?.message ||
                res.message ||
                'Something went wrong!'
            );
          }
        });
      }
    };
    fetchConsentData();
  }, [bankList]);

  useEffect(() => {
    let endDate = moment().format('YYYY-MM-DD ');

    let selectedProduct = consent?.find(
      (item) => item.accountNumber === selectAccount
    );
    if (selectedProduct && selectAccount?.length) {
      endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
        'YYYY-MM-DD'
      );
      let dateRangArray = [
        {
          startDate: selectedProduct?.consent?.data?.dateFrom,
          endDate: selectedProduct?.consent?.data?.dateTo,
        },
      ];
      calculateDateRang(dateRangArray, setSelectedDateRang);
      setSelectedConsent(selectedProduct);
    } else {
      let dateRangArray = consent?.map((v) => {
        return {
          startDate: v.consent?.data?.dateFrom,
          endDate: v.consent?.data?.dateTo,
        };
      });
      let allEndDate = consent?.map((v) => v.consent?.data?.dateTo);
      let maxEndDate =
        allEndDate?.length &&
        allEndDate.reduce(function (a, b) {
          return a > b ? a : b;
        });
      calculateDateRang(dateRangArray, setSelectedDateRang);
      endDate = moment(maxEndDate).format('YYYY-MM-DD');
      setEndDate(endDate);
    }
  }, [selectAccount, consent, selectMonth]);

  useEffect(() => {
    if (totalInFlow.length) {
      const result = totalInFlow.reduce(
        (total, thing) => total + thing[selectedType],
        0
      );
      setGrantInflowTotal(result);
    } else {
      setGrantInflowTotal(0);
    }
    if (totalOutFlow.length) {
      const result = totalOutFlow.reduce(
        (total, thing) => total + thing[selectedType],
        0
      );
      setGrantOutFlowTotal(result);
    } else {
      setGrantOutFlowTotal(0);
    }
  }, [selectedType, totalInFlow, totalOutFlow]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoader(true);
      let dateArray = [];
      let userData = getUserData();
      let data = selectedDateRang.length;
      monthRange.forEach((v, index) => {
        if (data >= v.value) {
          dateArray.push(v);
        }
      });
      setFilletElement(dateArray);
      let dateToValue = selectMonth
        ? [...filletElement, ...selectedDateRang].find((item) => {
            if (selectedEndDate) {
              return item.value === selectedEndDate;
            } else {
              return item.value === selectMonth;
            }
          })
        : filletElement[filletElement.length - 1];
      setDateToValueData(dateToValue);
      let payload = {
        userId: userData?.id,
        date_to:
          dateToValue?.title &&
          moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
            ? dateToValue?.value
            : endDate,
        n_months:
          dateToValue?.title &&
          moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
            ? 1
            : selectMonth || dateArray[dateArray.length - 1]?.value,
        account_id: selectAccount === 'all' ? null : selectAccount,
        testMLModel: selectedML,
        accountType: selectedConsent?.accountType,
        consentId: selectedConsent?.consentId,
        show_reversals: !checked,
      };
      if (payload?.userId && payload?.date_to && payload?.n_months) {
        GetAxiosInstance('orchestrator/get-dashboard', 'post', payload, {
          'journey-type': 'Dashboard',
        }).then((res) => {
          if (res?.data?.codes === 200) {
            setLoader(false);
            let getIncome = res.data?.data?.data.filter((item) => {
              return (
                item.aggregation_lvl === 1 &&
                item.tran_type === 'I' &&
                item.category_lvl1 === 'Income'
              );
            });
            let getExpense = res.data?.data?.data.filter((item) => {
              return (
                item.aggregation_lvl === 1 &&
                item.tran_type === 'E' &&
                item.category_lvl1 === 'Expense'
              );
            });
            let getAffordability = res.data?.data?.data.filter((item) => {
              return (
                item.aggregation_lvl === 1 &&
                item.tran_type === null &&
                item.category_lvl1 === 'Affordability'
              );
            });
            let liquidity = res.data?.data?.liquidity;

            const lq = liquidity.find((c) => c.recommendation === 'Low');
            setLiquidity_ResidualIncome(lq?.average_residual_income);
            setLiquidity_Predicted_balance(lq?.predicted_balance);
            let getInFlow = res.data?.data?.data.filter((item) => {
              return (item.aggregation_lvl =
                1 &&
                item.tran_type === 'I' &&
                item.category_lvl1 != 'Inflow Reversals' &&
                item.category_lvl2 &&
                item[selectedType] &&
                item[selectedType] !== 0);
            });
            let getOutFlow = res.data?.data?.data.filter((item) => {
              return (item.aggregation_lvl =
                1 &&
                item.tran_type === 'E' &&
                item.category_lvl2 &&
                item[selectedType] &&
                item[selectedType] !== 0);
            });

            let getRiskIndicators = res.data?.data?.data.filter((item) => {
              return (
                item.is_risky === 1 &&
                item.category_lvl2 &&
                item.category_lvl1 != 'Inflow Reversals'
              );
            });

            setTotalIncome(getIncome);
            setTotalExpense(getExpense);
            setTotalAffordability(getAffordability);
            setTotalInFlow(getInFlow);
            setTotalOutFlow(getOutFlow);
            setRiskIndicators(getRiskIndicators);
            setMonthlyLiquidity(liquidity);
            setDashboardData(res?.data?.data?.monthly);
          } else {
            setTotalIncome([]);
            setTotalExpense([]);
            setTotalAffordability([]);
            setTotalInFlow([]);
            setTotalOutFlow([]);
            setRiskIndicators([]);
            setDashboardData(res?.data?.data?.monthly);
            setLoader(false);
            toast.error(
              res?.response?.data?.message ||
                res.message ||
                'Something went wrong!'
            );
          }
        });
      }
    };
    fetchDashboardData();
  }, [selectedDateRang, endDate, selectedML, checked]);

  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === 'affordability_full'
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== 'all' && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      setSelectedAccountType(maxObject?.accountType);

      if (bankList.length) {
        const selectedIcon = bankList.find(
          (i) => maxObject?.bankName === i.bankName
        );
        setSelectedBankLogo(selectedIcon?.icon);
      }
    }
  }, [consent, bankList, checked]);

  const handleClipboard = () => {
    navigator.clipboard.writeText(`${uniqueKey}`);
    toast.success('You unique code copied successfully');
  };

  const handleSwitchChange = (event) => {
    setSwitchState(event.target.checked);
  };

  return (
    <>
      <>
        <DashboardUINav dashboardData={dashboardData} consent={consent} />
        <OverviewWrapper>
          <PageBoxWrapper>
            {!loader ? (
              <>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mt: { lg: 5, xs: 15 } }}
                >
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    gap={1}
                    sx={{ flexGrow: 1 }}
                  >
                    <PageHead>
                      <Box>
                        <Typography
                          variant="Body_light_14"
                          sx={{
                            fontWeight: 'bold',
                            color: 'common.white',
                          }}
                        >
                          Your Unique Share Code :{' '}
                        </Typography>
                        <Typography
                          sx={{ fontSize: '16px', textAlign: 'center' }}
                          variant="Body_light_14"
                          color="orange.main"
                        >
                          <span ref={ref}>{uniqueKey}</span>
                        </Typography>
                      </Box>

                      <Box
                        id="copy-btn"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleClipboard}
                      >
                        <ContentCopyIcon />
                      </Box>
                      <ReactTooltip
                        anchorId="copy-btn"
                        place="bottom"
                        content="copy"
                      />
                    </PageHead>
                  </Stack>

                  <Box sx={{ flexGrow: 2, mt: { sm: 0, xs: 5 } }}>
                    <Grid container gap={2} justifyContent="flex-end">
                      <Grid
                        item
                        md={2}
                        xs={12}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          variant="Body_medium_16"
                          color="orange.main"
                        >
                          Reversals
                        </Typography>
                        <Switch
                          checked={checked}
                          onChange={handleChange}
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: checked
                                ? theme.palette.orange.main
                                : '#002637', // Change thumb color based on checked state
                              '& + .MuiSwitch-track': {
                                backgroundColor: 'white', // Keep track background white
                              },
                            },
                            '& .MuiSwitch-thumb': {
                              width: 25,
                              height: 25,
                              boxShadow: 'none',
                              backgroundSize: 'cover', // Example of setting background size for the thumb
                              backgroundImage: 'url("path/to/your/image.jpg")', // Example of setting a background image for the thumb
                            },
                            '& .MuiSwitch-switchBase': {
                              color: checked
                                ? theme.palette.orange.main
                                : '#002637', // Change thumb color based on checked state
                              '& + .MuiSwitch-track': {
                                backgroundColor: 'white', // Keep track background white
                              },
                            },
                            '& .MuiSwitch-track': {
                              backgroundColor: 'white', // Ensure track background is white by default
                            },
                          }}
                        />
                      </Grid>

                      {ML_SELECTION_AVAILABLE && (
                        <Grid item md={2.5} xs={12}>
                          <Typography
                            variant="Body_medium_16"
                            color="orange.main"
                          >
                            TestML Model
                          </Typography>
                          <Autocomplete
                            id="select-business-partner"
                            options={mlOptions || []}
                            getOptionLabel={(option) => option.title || ''}
                            PaperComponent={CustomPaper}
                            value={
                              mlOptions?.find(
                                (item) => item.value === selectedML
                              ) || ''
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="filled"
                                id="filled-hidden-label-normal"
                                placeholder="Select..."
                                inputProps={{ 'aria-label': 'Without label' }}
                                hiddenLabel
                                fullWidth
                                {...params}
                              />
                            )}
                            onChange={(e, value) => {
                              setSelectedML(value?.value);
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item md={2} xs={12}>
                        <Typography
                          variant="Body_medium_16"
                          color="orange.main"
                        >
                          Account Number
                        </Typography>
                        <Autocomplete
                          id="select-business-partner"
                          options={accountsNumberList || []}
                          disableClearable
                          getOptionLabel={(option) => option.title || ''}
                          PaperComponent={CustomPaper}
                          value={
                            accountsNumberList?.find(
                              (item) => item.value === selectAccount
                            ) || ''
                          }
                          renderOption={(props, option) => (
                            <Box
                              {...props}
                              component="li"
                              key={props['data-option-index']}
                              name={option.bankName}
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            >
                              <img
                                loading="lazy"
                                name={option.bankName}
                                width="35"
                                style={{ borderRadius: '50%' }}
                                src={option.icon}
                                alt=""
                              />
                              {option.title}
                            </Box>
                          )}
                          renderInput={(params, option) => (
                            <TextField
                              variant="filled"
                              id="filled-hidden-label-normal"
                              placeholder="Select..."
                              inputProps={{ 'aria-label': 'Without label' }}
                              hiddenLabel
                              fullWidth
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {selectedBankLogo && (
                                      <Box
                                        component="img"
                                        src={selectedBankLogo}
                                        alt=""
                                        sx={{
                                          width: '35px',
                                          height: '35px',
                                          borderRadius: '50%',
                                        }}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          onChange={(e, value) => {
                            setSelectAccount(value?.value || null);
                            setSelectedBankLogo(value?.icon);
                          }}
                        />
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <Typography
                          variant="Body_medium_16"
                          color="orange.main"
                        >
                          Type
                        </Typography>
                        <Autocomplete
                          id="select-business-partner"
                          options={typeOptions || []}
                          getOptionLabel={(option) => option.title || ''}
                          PaperComponent={CustomPaper}
                          value={
                            typeOptions.find(
                              (item) => item.value === selectedType
                            ) || ''
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="filled"
                              id="filled-hidden-label-normal"
                              placeholder="Select..."
                              inputProps={{ 'aria-label': 'Without label' }}
                              hiddenLabel
                              fullWidth
                              {...params}
                            />
                          )}
                          onChange={(e, value) => {
                            setSelectedType(value.value);
                          }}
                        />
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <Typography
                          variant="Body_medium_16"
                          color="orange.main"
                        >
                          Filter Period
                        </Typography>
                        <Autocomplete
                          id="select-business-partner"
                          options={
                            [...filletElement, ...selectedDateRang] || []
                          }
                          getOptionLabel={(option) => {
                            if (
                              moment(option?.title).format('MMMM - YYYY') !==
                              'Invalid date'
                            ) {
                              return moment(option?.title).format(
                                'MMMM - YYYY'
                              );
                            } else {
                              return option?.title;
                            }
                          }}
                          PaperComponent={CustomPaper}
                          value={
                            selectMonth
                              ? [...filletElement, ...selectedDateRang].find(
                                  (item) => {
                                    if (selectedEndDate) {
                                      return item.value === selectedEndDate;
                                    } else {
                                      return item.value === selectMonth;
                                    }
                                  }
                                )
                              : filletElement[filletElement.length - 1] || ''
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="filled"
                              id="filled-hidden-label-normal"
                              placeholder="Select..."
                              inputProps={{ 'aria-label': 'Without label' }}
                              hiddenLabel
                              fullWidth
                              {...params}
                            />
                          )}
                          onChange={(e, newData) => {
                            setSelectMonth(newData?.value);
                            setSelectedEndDate(newData?.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
                {selectAccount !== 'all' && selectedConsent?.balance && (
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: { lg: 5, xs: 15 } }}
                  >
                    <Stack
                      direction="column"
                      flexWrap="wrap"
                      alignItems="left"
                      gap={1}
                      sx={{ flexGrow: 1 }}
                    >
                      <Box>
                        <Typography
                          variant="Body_semibold_32"
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          Balance : ₦{' '}
                          {selectedConsent.balance.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </Box>
                      <Stack>
                        <Typography
                          sx={{ fontSize: '16px', textAlign: 'left' }}
                          variant="Body_semibold_32"
                          color="orange.main"
                        >
                          As at{' '}
                          {format(
                            new Date(selectedConsent.createdAt),
                            'dd/MM/yyyy'
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                <Grid container spacing={5} alignItems="flex-start" mt={2}>
                  <Grid item md={4} xs={12}>
                    <TopCard>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={handleClickOpenIncomeDialog}
                      >
                        <Typography variant="Body_medium_16">Income</Typography>
                        <ChevronRightIcon />
                      </Stack>
                      <TopCardInfoBox>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography variant="Body_light_14">
                            {selectedType === 'tran_amount_monthly_average'
                              ? 'Monthly Average'
                              : 'Total'}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                            mt: 1,
                          }}
                        >
                          <Typography variant="Body_semibold_32">
                            ₦{' '}
                            {(
                              (totalIncome.length &&
                                totalIncome[0]?.[selectedType]) ||
                              0
                            )?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{' '}
                          </Typography>
                          <Box id="expenses" sx={{ marginLeft: '15px' }}>
                            <CircularProgressWrapper
                              sx={{
                                width: 'auto',
                                cursor: 'arrow',
                              }}
                            >
                              <StyledCircularProgress
                                progressValue={(totalIncome[0]?.confidence_level
                                  ? totalIncome[0]?.confidence_level * 100
                                  : 0
                                ).toFixed(1)}
                                indicatorColor={theme.palette.success.main}
                                type="percentage"
                                size="80"
                              />
                            </CircularProgressWrapper>
                          </Box>
                        </Box>
                        <ReactTooltip
                          anchorId="expenses"
                          place="bottom"
                          content={'Confidence Level'}
                        />
                      </TopCardInfoBox>
                      <Grid container my={3} spacing={2}>
                        <Grid item lg={4} md={6} xs={6}>
                          <CircularProgressWrapper>
                            <Typography
                              variant="Body_medium_14"
                              sx={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Amount Consistency
                            </Typography>
                            <StyledCircularProgress
                              progressValue={(
                                (totalIncome.length &&
                                  parseFloat(
                                    totalIncome[0]?.tran_amount_consistency *
                                      100
                                  )) ||
                                0
                              ).toFixed(2)}
                              selectedEndDate={selectedEndDate}
                              indicatorColor={theme.palette.success.main}
                            />
                          </CircularProgressWrapper>
                        </Grid>
                        <Grid item lg={4} md={6} xs={6}>
                          <CircularProgressWrapper>
                            <Typography
                              variant="Body_medium_14"
                              sx={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Regularity
                            </Typography>
                            <StyledCircularProgress
                              progressValue={(
                                (totalIncome.length &&
                                  parseFloat(
                                    totalIncome[0]?.tran_amount_regularity * 100
                                  )) ||
                                0
                              ).toFixed(2)}
                              selectedEndDate={selectedEndDate}
                              indicatorColor={theme.palette.success.main}
                            />
                          </CircularProgressWrapper>
                        </Grid>
                        <Grid item lg={4} md={6} xs={6}>
                          <CircularProgressWrapper
                            sx={{
                              width: 'auto',
                              cursor: 'arrow',
                            }}
                          >
                            <Typography
                              variant="Body_medium_14"
                              sx={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Longevity
                            </Typography>
                            <StyledCircularProgress
                              progressValue={
                                (totalIncome.length &&
                                  parseInt(
                                    totalIncome[0]?.tran_amount_longevity * 100
                                  )) ||
                                0
                              }
                              type="noCirculation"
                              selectMonth={
                                selectMonth
                                  ? filletElement.find(
                                      (item) => item.value === selectMonth
                                    )?.value
                                  : filletElement[filletElement.length - 1]
                                      ?.value
                              }
                              selectedEndDate={selectedEndDate}
                              indicatorColor={theme.palette.orange.main}
                            />
                          </CircularProgressWrapper>
                        </Grid>
                      </Grid>
                    </TopCard>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TopCard>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={handleClickOpenExpensesDialog}
                      >
                        <Typography variant="Body_medium_16">
                          Expenses
                        </Typography>
                        <ChevronRightIcon />
                      </Stack>{' '}
                      <TopCardInfoBox>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography variant="Body_light_14">
                            {selectedType === 'tran_amount_monthly_average'
                              ? 'Monthly Average'
                              : 'Total'}{' '}
                            Expenses{' '}
                          </Typography>
                        </Box>

                        <Typography variant="Body_semibold_32">
                          ₦{' '}
                          {totalExpense.length &&
                          totalExpense[0]?.[selectedType] < 0 ? (
                            <>
                              {(
                                (totalExpense.length &&
                                  totalExpense[0]?.[selectedType]) ||
                                0
                              )
                                .toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                })
                                .replace('-', '')}
                            </>
                          ) : (
                            <>
                              {(
                                (totalExpense.length &&
                                  totalExpense[0]?.[selectedType]) ||
                                0
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </>
                          )}
                        </Typography>
                      </TopCardInfoBox>
                      <TopCardListWrapper>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            Expenses
                          </Typography>

                          <Stack direction="column" alignItems="end">
                            <Typography variant="Body_medium_14">
                              ₦{' '}
                              {totalExpense.length &&
                              totalExpense[0]?.[selectedType] < 0 ? (
                                <>
                                  {(
                                    (totalExpense.length &&
                                      totalExpense[0]?.[selectedType]) ||
                                    0
                                  )
                                    .toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })
                                    .replace('-', '')}
                                </>
                              ) : (
                                <>
                                  {(
                                    (totalExpense.length &&
                                      totalExpense[0]?.[selectedType]) ||
                                    0
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}
                                </>
                              )}
                            </Typography>
                            <Typography variant="Body_light_12">
                              {selectedType === 'tran_amount_monthly_average'
                                ? 'Monthly Average'
                                : 'Total'}
                            </Typography>
                          </Stack>
                        </TopCardListItem>

                        <StyledDivider2 />
                      </TopCardListWrapper>
                    </TopCard>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TopCard>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={handleClickOpenAffordabilityDialog}
                      >
                        <Typography variant="Body_medium_16">
                          Liquidity
                        </Typography>
                        <ChevronRightIcon />
                      </Stack>{' '}
                      <Grid container>
                        <TopCardInfoBox item md={6} xs={12} mx={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography variant="Body_light_14">
                              Average Residual Income
                            </Typography>
                          </Box>
                          <Typography variant="Body_semibold_32">
                            ₦{' '}
                            {liquidity_ResidualIncome?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{' '}
                          </Typography>
                        </TopCardInfoBox>
                        <TopCardInfoBox item md={6} xs={12} pl={4}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography variant="Body_light_14">
                              Predicted Balance
                            </Typography>
                          </Box>
                          <Typography variant="Body_semibold_32">
                            ₦{' '}
                            {liquidity_Predicted_balance?.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{' '}
                          </Typography>
                        </TopCardInfoBox>
                      </Grid>
                      <TopCardListWrapper>
                        <TopCardListItem>
                          <Typography variant="Body_medium_14">
                            Loan Recommendation
                          </Typography>
                        </TopCardListItem>
                      </TopCardListWrapper>
                      <TopCardListWrapper>
                        {monthlyLiquidity?.map((liquidity, index) => {
                          return (
                            <Box component={'span'} key={index}>
                              <TopCardListItem>
                                <Typography variant="Body_light_14">
                                  {liquidity.months} months
                                </Typography>
                                <Stack direction="column" alignItems="end">
                                  <Typography variant="Body_semibold_14">
                                    ₦{' '}
                                    {liquidity.liquidity_amount.toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}{' '}
                                  </Typography>
                                  <Typography variant="Body_light_12"></Typography>
                                </Stack>
                              </TopCardListItem>

                              <StyledDivider2 />
                            </Box>
                          );
                        })}
                      </TopCardListWrapper>
                    </TopCard>
                  </Grid>
                </Grid>
                <Grid container spacing={5} alignItems="center" mt={5}>
                  <Grid item md={7} xs={12}>
                    <TopCard>
                      <Grid container spacing={1} alignItems="flex-start">
                        <Grid item md={6} xs={12}>
                          <TableWrapper>
                            <Table
                              stickyHeader
                              sx={{
                                minWidth: 350,
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCellHead>Inflow</TableCellHead>
                                  <TableCellHead />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {totalInFlow?.map((row, index) => (
                                  <TableRow hover key={index}>
                                    <TableCellDataItemName>
                                      {row.category_lvl2}
                                    </TableCellDataItemName>
                                    <TableCellDataAmount>
                                      ₦{' '}
                                      {(
                                        row?.[selectedType] || 0
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                      })}
                                    </TableCellDataAmount>
                                  </TableRow>
                                ))}
                                <TableRow hover>
                                  <TableCellDataItemName>
                                    Total
                                  </TableCellDataItemName>
                                  <TableCellDataAmount>
                                    ₦{' '}
                                    {(grantInflowTotal || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </TableCellDataAmount>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableWrapper>{' '}
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TableWrapper>
                            <Table
                              stickyHeader
                              sx={{
                                minWidth: 350,
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCellHead>Outflow</TableCellHead>
                                  <TableCellHead />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {totalOutFlow?.map((row) => (
                                  <TableRow hover key={row.category_lvl2}>
                                    <TableCellDataItemName>
                                      {row.category_lvl2}
                                    </TableCellDataItemName>
                                    <TableCellDataAmount>
                                      ₦{' '}
                                      {(
                                        row?.[selectedType] || 0
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                      })}
                                    </TableCellDataAmount>
                                  </TableRow>
                                ))}
                                <TableRow hover>
                                  <TableCellDataItemName>
                                    Total
                                  </TableCellDataItemName>
                                  <TableCellDataAmount>
                                    ₦{' '}
                                    {(grantOutFlowTotal || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </TableCellDataAmount>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableWrapper>{' '}
                        </Grid>
                      </Grid>
                    </TopCard>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TopCard sx={{ overflow: 'auto' }}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="Body_medium_16">
                          Risk Indicators
                        </Typography>
                      </Stack>
                      <RiskCardInfoWrapper>
                        <Grid container spacing={2}>
                          {riskIndicators?.map((row, index) => (
                            <>
                              {row?.[selectedType] && (
                                <Grid
                                  container
                                  key={index.toString()}
                                  md={12}
                                  xs={12}
                                  item
                                  alignItems="center"
                                  spacing={1}
                                  sx={{
                                    padding: theme.spacing(2),
                                    borderBottom: '2px solid #002E45',
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="flex-start"
                                    gap={1}
                                  >
                                    <Stack direction="column">
                                      {row?.comments?.length ? (
                                        <>
                                          {row?.comments?.map((item) => {
                                            return (
                                              <Box component="li">
                                                <Typography variant="Body_semibold_12">
                                                  {item}
                                                </Typography>
                                              </Box>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Stack>
                                  </Stack>
                                </Grid>
                              )}
                            </>
                          ))}
                        </Grid>
                      </RiskCardInfoWrapper>
                    </TopCard>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    opacity: '0.5',
                  }}
                >
                  Data Fetch from{' '}
                  {moment(
                    moment(dateToValueData?.title).format('MMMM - YYYY') !==
                      'Invalid date'
                      ? dateToValueData?.value
                      : endDate
                  )
                    .subtract(
                      moment(dateToValueData?.title).format('MMMM - YYYY') !==
                        'Invalid date'
                        ? 1
                        : selectMonth ||
                            filletElement[filletElement.length - 1].value,
                      'month'
                    )
                    .format('DD/MM/YYYY')}{' '}
                  to{' '}
                  {moment(
                    moment(dateToValueData?.title).format('MMMM - YYYY') !==
                      'Invalid date'
                      ? dateToValueData?.value
                      : endDate
                  ).format('DD/MM/YYYY')}
                </Box>
              </>
            ) : (
              <Loader />
            )}
          </PageBoxWrapper>
        </OverviewWrapper>
      </>
      <MonthlyIncome
        open={openIncome}
        dashboardData={dashboardData}
        selectedType={selectedType}
        totalIncome={totalIncome}
        handleCloseDialog={handleCloseIncomeDialog}
      />
      <MonthlyExpenses
        open={openExpenses}
        dashboardData={dashboardData}
        selectedType={selectedType}
        totalExpense={totalExpense}
        handleCloseDialog={handleCloseExpensesDialog}
      />
      <MonthlyAffordability
        open={openAffordability}
        dashboardData={dashboardData}
        selectedType={selectedType}
        totalAffordability={totalAffordability}
        handleCloseDialog={handleCloseAffordabilityDialog}
      />
    </>
  );
};

function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange?.length &&
    allDateRange?.forEach((v) => {
      var startDate = moment(v.startDate);
      var endDate = moment(v.endDate);
      if (endDate.isBefore(startDate)) {
        return;
      }
      while (startDate.isBefore(endDate)) {
        let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
        let diff = moment(endDate).diff(moment(lastDate), 'days');
        if (diff < 0) {
          lastDate = moment(endDate).format('YYYY-MM-DD');
        }
        result.push(lastDate);
        startDate.add(1, 'month');
      }
      result
        .sort(
          (a, b) =>
            new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
        )
        .reverse();
      function filterUniqueDates(data) {
        const lookup = new Set();
        return data.filter((date) => {
          const serialised = new Date(date).getTime();
          if (lookup.has(serialised)) {
            return false;
          } else {
            lookup.add(serialised);
            return true;
          }
        });
      }
      let uniqueDates = filterUniqueDates(result);
      uniqueDates =
        uniqueDates.length &&
        uniqueDates.map((i) => {
          return {
            title: i,
            value: i,
          };
        });
      setSelectedDateRang(uniqueDates);
    });
}

export default Overview;
