import { styled } from "@mui/material/styles";
import {
  Box,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MainCard from "../../components/common/MainCard";

//

export const RAICard = styled(MainCard)(({ theme }) => ({
  height: 'auto',
  paddingLeft: theme.spacing(0),
  // margin: theme.spacing(2, 1),
  margin: theme.spacing(0),

  [theme.breakpoints.up('md')]: {
    height: '350px',
    // margin: theme.spacing(0, 1.5),
  },
  [theme.breakpoints.up('sm')]: {
    height: '350px',
  },
}));

export const TableCard = styled(MainCard)(({ theme }) => ({
  height: 'auto',
  paddingLeft: theme.spacing(0),
  // margin: theme.spacing(2, 1),
  margin: theme.spacing(0),

  [theme.breakpoints.up('md')]: {
    height: '590px',
    // margin: theme.spacing(0, 1.5),
  },
}));



export const RAICardInfoBox = styled(Box)(({ theme }) => ({
  // margin: theme.spacing(3.75, 0, 3.25, 0),
  gap: theme.spacing(0.75),
  display: 'flex',
  flexDirection: 'column',
}));

export const SecondaryCard = styled(MainCard)(({ theme }) => ({
  minHeight: 'auto',
  // margin: theme.spacing(2, 1.5),
  margin: theme.spacing(0),

  [theme.breakpoints.up('sm')]: {
    minHeight: '170px',
  },
}));

export const AmountText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_semibold_32,
  },
}));

export const DirectDebitWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: theme.spacing(5),

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(0),
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `3px solid ${theme.palette.primary.lighter}`,
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "auto",
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    maxHeight: "500px",
  },
}));

export const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  // border: "none",
  ...theme.typography.Body_medium_16,
  color: theme.palette.orange.main,
}));

export const StyledTableCellData = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  ...theme.typography.Body_light_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_light_16,
  },
}));

export const StyledTableCellDataItem = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));


export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: "auto",
  overflowX: "hidden",
  marginTop: "40px",
  [theme.breakpoints.up("sm")]: {
    maxHeight: "500px",
  },
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  // border: "none",
  backgroundColor: theme.palette.primary.light,
  borderBottom: `3px solid ${theme.palette.primary.lighter}`,

  color: theme.palette.grey.main,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));


export const ExpandListBtn = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: "12px",
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "space-between",
  maxWidth: "160px",
  cursor: "pointer",
  position: "absolute",
  marginLeft: "500px",
  color: theme.palette.common.white,
  textDecoration: "none",

  ":hover": {
    backgroundColor: theme.palette.primary.lighter,
  },

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
