import axios from "axios";
import { getUserData } from "./common";

const BASE_URL = process.env.REACT_APP_EDOC_API_URL || "";
let setSessionExpired = null; 

export function setSessionExpiredHandler(handler) {
  setSessionExpired = handler;
}

export default function GetAxiosInstance(link, method, payload, headers, token) {
  let userData = getUserData();
  let requestObj = {
    method: method,
    url: `${BASE_URL}/${link}`,
    data: payload,
    headers: {
      Authorization: token || `Bearer ${userData?.accessToken}`,
      'app-name': process.env.REACT_APP_NAME,
      'app-version': process.env.REACT_APP_VERSION,
      ...headers,
    },
  };

  return axios(requestObj)
    .then((res) => res)
    .catch((err) => {

      if (
        link !== "auth/login" &&
        err?.response?.status === 401 &&
        err?.response?.data?.message === "Token type is invalid!"
      ) {
        if (setSessionExpired) setSessionExpired(true);
      }
      return Promise.reject(err);
    });
}
