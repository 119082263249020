import React, { useState } from "react";

import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  StyledTypography,
  FormInputWrapper,
  StyledAction,
} from "./AuthStyle";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import InputLabel from "../../components/common/formStyle/InputLabel";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { toast } from "react-toastify";
import GetAxiosInstance from "../../components/common/api";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export function ForgotPassword() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState();
  const theme = useTheme();

  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const handleClick = () => {
    if (!email) {
      setEmailError("email is a required field");
      return;
    }
    if (!emailRegex.test(email)) {
      toast.error("email is not valid ");
      return;
    }
    setLoading(true);
    GetAxiosInstance(
      `auth/forgotPassword`,
      'post',
      { email },
      {
        'journey-type': 'forgotPassword',
      }
    ).then((res) => {
      setLoading(false);
      if (res?.data?.codes === 200) {
        let obj = {
          email: email,

          flow: 'resetPassword',
        };
        navigate('/email-verification', { state: obj });
      } else {
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  };
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle component="form" autoComplete="off">
            <Stack>
              <FormInfoBox>
                <StyledTypography variant="Body_semibold_20">
                  Forgot your password?
                </StyledTypography>
                <Typography variant="Body_semibold_24" color="common.white">
                  Please enter your email...
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid item xs={12}>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    variant="filled"
                    type="email"
                    placeholder="Enter email address"
                    id="filled-hidden-label-normal"
                    fullWidth
                    inputProps={{ "aria-label": "Without label" }}
                    hiddenLabel
                    helperText={emailError && emailError}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                </Grid>
              </FormInputWrapper>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={handleClick}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: "60px",
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Reset My Password{" "}
                </LoadingButton>
              </AnimateButton>

              <Typography
                textAlign="center"
                mt={4}
                underline="none"
                variant="Body_medium_16"
                color="common.white"
              >
                I remember my password. &nbsp;
                <StyledAction
                  // underline="none"
                  variant="Body_medium_16"
                  component={RouterLink}
                  to="/login"
                >
                  Log In
                </StyledAction>
              </Typography>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
