import React, { useState } from "react";

import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  Box,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  ContentStyle,
  FormStyle,
  FormInfoBox,
  StyledTypography,
  FormInputWrapper,
  SignupBg,
} from "./AuthStyle";

import InputLabel from "../../components/common/formStyle/InputLabel";
import CustomizedCheckbox from "../../components/common/formStyle/CheckBoxStyled";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { useNavigate } from "react-router-dom";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

export function Signup() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [isCheck, setIsCheck] = useState(false);
  const theme = useTheme();
  const formSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const onSubmit = (data) => {
    let obj = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    };
    if (!isCheck) {
      toast.error('Please select checkbox');
      return;
    }
    setLoading(true);

    GetAxiosInstance(`auth/sendOtp`, 'post', obj, {
      'journey-type': 'Register',
    })
      .then((res) => {
        if (res?.data?.codes === 200) {
          setLoading(false);
          navigate('/email-verification', { state: obj });
          const postObj = {
            journey: 'CUSTOMER_REGISTRATION',
            eventName: 'CUSTOMER_REGISTRATION.INITIATED',
            status: 'SUCCESS',
            uniqueIdentifier: data.email,
            subIdentifier: '',
            data: {
              req: obj,
              res: res?.data?.message,
            },
            source: 'CONNECT',
          };
          GetAxiosInstance(`log/eventLog`, 'post', postObj);
        } else {
          setLoading(false);
          const postObj = {
            journey: 'CUSTOMER_REGISTRATION',
            eventName: 'CUSTOMER_REGISTRATION.INITIATED',
            status: 'ERROR',
            uniqueIdentifier: data.email,
            subIdentifier: '',
            data: {
              req: obj,
              res: res?.data?.message,
            },
            source: 'CONNECT',
          };
          GetAxiosInstance(`log/eventLog`, 'post', postObj);
          toast.error(res?.response?.data?.message || res.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err.message);
      });
  };
  return (
    <>
      <SignupBg>
        <ContentStyle sx={{ minHeight: '100vh' }}>
          <FormStyle
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <FormInfoBox>
                <StyledTypography variant="Body_semibold_20">
                  Hello
                </StyledTypography>
                <Typography variant="Body_semibold_24" color="common.white">
                  Please register your account
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid container spacing={4} alignItems="center">
                  <Grid item sm={6} xs={12}>
                    <InputLabel>First Name</InputLabel>
                    <TextField
                      variant="filled"
                      type="text"
                      placeholder="Enter first name"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('firstName')}
                      helperText={errors.firstName && errors.firstName.message}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Last Name</InputLabel>
                    <TextField
                      variant="filled"
                      type="text"
                      placeholder="Enter last name"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('lastName')}
                      helperText={errors.lastName && errors.lastName.message}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    variant="filled"
                    type="email"
                    placeholder="Enter email address"
                    id="filled-hidden-label-normal"
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    {...register('email')}
                    helperText={errors.email && errors.email.message}
                  />
                </Grid>
              </FormInputWrapper>

              <Grid item xs={12} lg={6} mb={4}>
                <Box
                  sx={{
                    display: 'flex',
                    textAlign: 'start',
                    justifyContent: 'flex-start',
                    gap: 2,
                  }}
                >
                  <CustomizedCheckbox
                    onClick={(e) => setIsCheck(e.target.checked)}
                  />

                  <Typography variant="Body_light_16" color="common.white">
                    By Creating an account you agree to accept our
                    <Box style={{ display: 'flex', gap: 4 }}>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        color="orange.main"
                        onClick={() =>
                          window.open(
                            'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+ng+Privacy+Policy.pdf'
                          )
                        }
                      >
                        Privacy Policy
                      </Box>{' '}
                      and
                      <Box
                        sx={{ cursor: 'pointer' }}
                        color="orange.main"
                        onClick={() =>
                          window.open(
                            'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+Ng+Terms+%26+Conditions.pdf'
                          )
                        }
                      >
                        Terms of Service
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              </Grid>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: '100%' }}
                  // onClick={onSubmit}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Create Account
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </SignupBg>
    </>
  );
}

// Checkbox
